
.section-feedback {
    width: 100%;
    padding: 6rem 0;
    margin: 0 auto;
    background-color: rgba(61, 63, 65, .5);
}

.section-feedback__title {
    font-size: 4.1rem;
    font-weight: 320;
    margin-bottom: 3rem;
    text-align: center;
    color: #fff;
    line-height: 5rem;
    letter-spacing: 0.2rem;
    text-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.4);

}

.section-feedback__text {
    font-size: 2rem;
    color: #ddd;
    width: 80%;
    margin: 2rem auto 4rem auto;
    text-align: center;
}

.block-feedback-cards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.feedback-card__element {
    background-color: #dddddd;
    width: 70%;
    min-height: 18rem;
    display: flex;
    margin-bottom: 5rem;
}

.feedback-card__image-wrapper {
    margin: auto 0;
}

.feedback-card__image {
    width: 20rem;
    margin-left: -1.3rem;
    border: .2rem solid #E3929B;
}

.feedback-card__text {
    padding: 0 2rem;
}

.feedback-card__text-title {
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 1rem;
}

.feedback-card__text-name {
    font-size: 1.2rem;
    font-style: italic;
    color: #E3929B;
    margin: 1rem 0 1rem 0;
}

.feedback-card__text-paragraph {
    font-size: 1.3rem;
    font-style: italic;
    margin-bottom: 1rem;
}

@media(max-width: 815px) {


    .feedback-card__element {
        flex-direction: column;
        width: 50rem;
    }
    .feedback-card__image {
        margin-left: 0;
    }


}

@media(max-width: 500px) {


    .feedback-card__element {
        flex-direction: column;
        width: 38rem;
    }



}
