.wrapper {
    background-color: #eeeeee;
    width: 100%;
    /*padding: 50px 30px;*/
    margin: 0 auto;
}

.wrapper-about-container {
    padding: 50px 30px;
}

.wrapper h2 {
    font-size: 4.1rem;
    margin: 0 auto;
    width: 200px;
    font-weight: 400;
    color: #5E3C40;
    line-height: 5rem;
    letter-spacing: 0.2rem;
    text-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.4);
}

.wrapper h3 {
    font-size: 2.2rem;
    color: black;
    width: 90%;
    letter-spacing: 0.2rem;
    margin: 3rem;
    text-align: center;
    /*text-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.1);*/
}

.wrapper a {
    color: #5E3C40;
    text-decoration: underline;
    font-size: 2.2rem;
    width: 300px;
    letter-spacing: 0.2rem;
    margin: 3rem;
    /*text-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.2);*/
}
.wrapper h4 {
    margin: 3rem;
}

.skills-container p {
    font-size: 1.6rem;
    color: #dddddd;
    letter-spacing: 0.2rem;
    margin-bottom: 0.7rem;
    text-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.4);
}


.underline {
    width: 230px;
    margin: 20px auto;
    height: 2px;
    background-color: #5E3C40;
}

.skills-container {
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    background-color: #555555;
    padding: 20px;
    justify-content: space-around;
}

@media(max-width: 600px) {
    .skills-container {
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        width: 80%;
    }
}

.about-container {
    display: flex;
    flex-wrap: wrap;
}

.social-link-container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
