
.my-form {
 display: inline;
}

.portfolio--content__wrapper {
    max-width: 300px;
    background-color: #ddd;
    margin: 10rem 2rem;
    box-shadow: 0 3rem 6rem rgba(0, 0, 0, .3);
    padding: .1rem 3rem;
}

.portfolio--content__wrapper:hover {
    background-color: #eee;
}

.portfolio--content__title {
    font-size: 3.1rem;
    margin: 20px;
    width: 350px;
    font-weight: 300;
    color: #5E3C40;
    line-height: 5rem;
    letter-spacing: 0.2rem;
    text-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.4);
}

.portfolio--card-and-text {
    padding-bottom: 5rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.portfolio--text-container {
    margin: 1rem;
}

.portfolio--card-and-text__right {
    flex-direction: row-reverse;
}

.portfolio--text {
    width: 100%;
    font-size: 2rem;
    margin-bottom: 2rem;
}
.portfolio--text_title {
    width: 100%;
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.portfolio--view-detail-btn {
    width: 13rem;
    height: 3rem;
    background-color: #E3929B;
    border: none;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.4);
    cursor: pointer;

    margin-right: 20px;
    margin-top: 20px;
}

.portfolio--view-detail-btn:hover {
    background-color: #9F9697;
}


.btn-arrow {
    margin-left: .5rem;
}

.portfolio--view-detail-btn:hover .btn-arrow {
    margin-left: 1.5rem;
    transition: margin-left .5s;
}


/*portfolio 3d card*/

.portfolio--card {
    perspective: 80rem;
    margin: 0 4rem 3rem 4rem;
}


.portfolio--card__content {
    width: 36rem;
    height: 25rem;
    background-color: #34598f;
    background-size: cover;
    border-radius: 0.5rem;
    overflow: hidden;
}

.portfolio--card__content img {
    width: 100%;
    height: auto;
    overflow: hidden;
}




