
.portfolio--section__wrapper {
    width: 100vw;
    background-color: #D4D4D4;
    margin: 0 auto;
    padding: 6rem 0;
}

.portfolio--section__title {
    text-align: center;
    font-size: 4.1rem;
    font-weight: 400;
    color: #000;
    line-height: 5rem;
    letter-spacing: 0.2rem;
    text-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.4);
}

.project-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}


