
/*  business card*/

.contact-card__wrapper {
    perspective: 100rem;
    width: 55rem;
    height: 30rem;
    margin-top: 6rem;
}

.contact-card {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    position: relative;
    box-shadow: 0 1.5rem 6rem rgba(0, 0, 0, .3);
    border-radius: 1.5rem;
    transition: transform 1s;
}

.contact-card__wrapper:hover .contact-card {
    transform: rotateY(360deg);
}

.contact-card__front,
.contact-card__back {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotateX(0deg);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: inherit;
}

.contact-card__front {
    display: flex;
    background: linear-gradient(100deg, rgb(255, 255, 255) 40%, #E3929B 0);
}

.contact-card__front-left {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-card__img {
    width: 70%;
}

.contact-card__front-left h4 {
    margin: 1rem;
    font-size: 1.8rem;
    letter-spacing: .1rem;
}

.contact-card__front-left span {
    color: rgb(96, 91, 92);
}

.contact-card__front-right {
    width: 60%;
}

.contact-card__text {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    font-size: 1.5rem;
}

.contact-card__text i {
    width: 3.5rem;
    height: 3.5rem;
    border: .2rem solid #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(96, 91, 92);
    margin-right: 2rem;
    color: #eeeeee;
}

.contact-card__person {
    background-color: rgb(96, 91, 92);
    padding: .5rem 0 .5rem 3rem;
    margin: 3rem 0;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, .3);
    color: #eeeeee;
}

.contact-card__phone {
    padding-left: 3rem;
}

.contact-card__email {
    padding-left: 2rem;
}

.contact-card__text a {
    color: black;
}

.contact-card__address {
    padding-left: 1rem;
}

.contact-card__back {
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-card__back img {
    width: 45%;
    position: relative;
    z-index: 100;
    background-color: #eeeeee;
}


/* end of business card*/





/*end of contact me*/



@media(max-width: 540px) {
    .contact-card__wrapper:hover .contact-card {
        transform: rotateY(0);
    }
}



@media(max-width: 410px) {
    .contact-card__text {
        margin: 1.3rem;
        padding: .1rem;
        font-size: 1.1rem;
    }
    .contact-card__phone {
        padding-left: 1rem;
    }
    .contact-card__email {
        padding-left: .5rem;

    }

    .contact-card__address {
        padding-left: .1rem;
    }
    .contact-card__img {
        width: 40%;
    }
    .contact-card__wrapper {
        width: 35rem;
        height: 20rem;
    }
    .contact-card__text i {
        width: 2rem;
        height: 2rem;
    }
    .contact-card__front-right {
        width: 65%;
    }
    .contact-card__front-left h4 {
        font-size: 1.5rem;
    }
}
